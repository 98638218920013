import { useTranslation } from 'react-i18next'
import { useInView } from "framer-motion";
import { useRef } from "react";

function FadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-0px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </section>
  );
}

export const Portfolio = () => {
    const { t } = useTranslation()

    return (
      <div id="portfolio" className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col mb-6 lg:justify-between lg:flex-row md:mb-8">
        <FadeIn>
          <h2 className="pl-4 max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none text-left text-white">
              <span className="relative">
                <div className="absolute -inset-x-2 bottom-0 h-3 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink visible" />
                <span className="relative inline-block">
                {t('portfolio_title')}
                </span>
              </span>
          </h2>
          <p className="text-white lg:text-sm lg:max-w-md text-left">
            
          </p>
        </FadeIn>
        </div>
        <FadeIn>
        <div className="grid gap-8 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
            <div className="relative mx-auto shadow-lg w-80 sm:w-96 h-60">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/yXk64TLt1zE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="relative mx-auto shadow-lg w-80 sm:w-96 h-60">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/HE2Vqi0R_CM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="relative mx-auto shadow-lg w-80 sm:w-96 h-60">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/ArY17M5Ci7g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="relative mx-auto shadow-lg w-80 sm:w-96 h-60">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/JGVjM6Ha3zI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="relative mx-auto shadow-lg w-80 sm:w-96 h-60">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/Zrlsq3RBk9o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className="relative mx-auto shadow-lg w-80 sm:w-96 h-60">
            <iframe className="w-full h-full" src="https://www.youtube.com/embed/X2IwNqtZOkM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        </FadeIn>
      </div>
    );
  };

  export default Portfolio