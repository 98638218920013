import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import './index.css';
import 'flag-icon-css/css/flag-icons.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['sk', 'en'],
    fallbackLng: 'sk',
    debug: false,
    // Options for language detector
    detection: {
      order: ['htmlTag', 'path'],
    },
    react: { useSuspense: true },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })
  
  const loadingMarkup = (
    <div className="py-4 text-center bg-grey">
    </div>
  )


ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
