import martin from '../martin.jpg';
import igor from '../igor.jpg';
import dominik from '../dominik.jpg';
import { useTranslation } from 'react-i18next'
import { useInView } from "framer-motion";
import { useRef } from "react";

function FadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-0px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </section>
  );
}

export const Team = () => {
  const { t } = useTranslation()

    return (
      <div id='team' className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
        <FadeIn>
        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
              <span className="relative px-1">
                <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink" />
                <span className="relative inline-block text-white uppercase">
                {t('team_title')}
                </span>
              </span>
            </h2>
        </FadeIn>
        </div>
        <FadeIn>
        <div className="grid gap-10 mx-auto sm:grid-cols-2 lg:grid-cols-3 lg:max-w-screen-lg">
          <div className="mb-16">
            <div className="mx-auto relative pb-56 mb-4 rounded lg:pb-64 h-1/3 w-7/12">
              <img
                className="absolute object-contain w-full h-full rounded"
                src={martin}
                alt="Person"
              />
            </div>
            <div className="flex flex-col sm:text-center text-white">
              <p className="text-xl font-bold uppercase">Martin</p>
              <p className="mb-4 text-lg font-semibold text-gray-800">{t('team_martin_title')}</p>
              <p className="mb-5 text-md font-light text-gray-800">{t('team_martin_p')}</p>
            </div>
          </div>
          <div className="mb-16">
            <div className="mx-auto relative pb-56 mb-4 rounded lg:pb-64 h-1/3 w-7/12">
              <img
                className="absolute object-contain w-full h-full rounded"
                src={igor}
                alt="Person"
              />
            </div>
            <div className="flex flex-col sm:text-center text-white">
              <p className="text-xl font-bold uppercase">Igor</p>
              <p className="mb-4 text-lg font-semibold text-gray-800">{t('team_igor_title')}</p>
              <p className="mb-5 text-md font-light text-gray-800">{t('team_igor_p')}</p>
            </div>
          </div>
          <div className="mb-16">
            <div className="mx-auto relative pb-56 mb-4 rounded lg:pb-64 h-1/3 w-7/12">
              <img
                className="absolute object-contain w-full h-full rounded"
                src={dominik}
                alt="Person"
              />
            </div>
            <div className="flex flex-col sm:text-center text-white">
              <p className="text-xl font-bold uppercase">Dominik</p>
              <p className="mb-4 text-lg font-semibold text-gray-800">{t('team_dominik_title')}</p>
              <p className="mb-5 text-md font-light text-gray-800">{t('team_dominik_p')}</p>
            </div>
          </div>
        </div>
        </FadeIn>
      </div>
    );
  };

export default Team