import React, { useState } from 'react';
import logo from '../MFA_Production_Logo.svg';
import {Link} from 'react-scroll';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

export const Nav = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation()

    const languages = [
      {
        code: 'sk',
        name: 'Slovensky',
        country_code: 'sk',
      },
      {
        code: 'en',
        name: 'English',
        country_code: 'gb',
      },
    ]
  
    return (
      <div id='navbar' className="bg-grey drop-shadow-lg z-20 fixed top-0 left-0 right-0 opacity-100 sm:opacity-95">
        <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <nav className="relative flex items-center justify-between">
            <Link to='uvod' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer inline-flex items-center">
              <img src={logo} alt="MFA Production logo" width="60" />
            </Link>
            <ul class="flex items-center hidden space-x-8 lg:flex text-white uppercase effect-4">
            <li>
                <Link to='uvod' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                {t('nav_about')}
                </Link>
            </li>
              <li class="group  relative dropdown cursor-pointer font-bold text-base uppercase tracking-wide">
                <Link to='services' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                {t('nav_services')}
                </Link>
                  <div id='navbar-dropdown' class="-left-24 group-hover:block dropdown-menu absolute hidden h-auto">
                  <ul class="top-0 w-72 bg-grey shadow px-6 py-6">
                      <li class="mb-2">
                        <Link to='promo' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_promo')}
                        </Link>
                      </li>
                      <li class="mb-2">
                        <Link to='flight' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_aerial')}
                        </Link>
                      </li>
                      <li class="mb-2">
                        <Link to='animation' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_animation')}
                        </Link>
                      </li>
                      <li class="mb-2">
                        <Link to='time' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_timelapse')}
                        </Link>
                      </li>
                      <li class="mb-2">
                        <Link to='automotive' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_automotive')}
                        </Link>
                      </li>
                      <li class="mb-2">
                        <Link to='aftermovie' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_aftermovie')}
                        </Link>
                      </li>
                      <li class="mb-2">
                        <Link to='foreign' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_abroad')}
                        </Link>
                      </li>
                      <li class="mb-0">
                        <Link to='social' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                        {t('nav_services_social')}
                        </Link>
                      </li>
                  </ul>
                  </div>
              </li>
              <li>
                <Link to='portfolio' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                {t('nav_portfolio')}
                </Link>
              </li>
              <li>
                <Link to='team' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                {t('nav_team')}
                </Link>
              </li>
              <li>
                <Link to='contact' spy={true} smooth={true} offset={-50} duration={1500} className="cursor-pointer font-semibold tracking-wide text-gray-100 transition-colors duration-200 p-2">
                {t('nav_contact')}
                </Link>
              </li>
              <li>
                <div class="dropdown inline-block relative">
                  <button class="bg-grey text-gray-700 font-semibold py-2 px-4 rounded inline-flex items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="#ffffff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none">
                    <circle cx="12" cy="12" r="9" />
                    <path d="M12,3 C 8,3 8,21 12,21 C 16,21 16,3 12,3"/>
                    <path d="M3,12 C 3,8 21,8 21,12 C 21,16 3,16 3,12"/>
                  </svg>
                  </button>
                  <ul class="dropdown-menu absolute hidden text-gray-700 pt-1 -left-12 w-auto">
                    {languages.map( ({code, name, country_code}) => (
                      <li key={code} className="w-auto">
                        <button className='dropdown-item py-2 pr-24 hover:text-light-blue' 
                        onClick={()=> i18n.changeLanguage(code)}>
                          <span className={`flag-icon flag-icon-${country_code}`}>
                            <span className='px-8 uppercase font-semibold'>
                            {name}
                            </span>
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </li> 
            </ul>
            <div class="lg:hidden text-white opacity-90">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                class="p-2 mr-3 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg class="w-5 text-white" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div class="absolute top-0 left-0 w-full z-50">
                  <div id='mobile-navbar' class="px-5 py-2 bg-grey rounded shadow-lg">
                    <div class="flex items-center justify-between mb-4">
                      <div>
                        <a
                          href="/"
                          aria-label="Company"
                          title="Company"
                          class="inline-flex items-center"
                        >
                          <span class="ml-2 text-xl font-bold tracking-wide text-white uppercase">
                            Menu
                          </span>
                        </a>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul class="py-2 space-y-4 uppercase">
                        <li>
                          <Link to='uvod' spy={true} smooth={true} offset={-50} duration={1500} onClick={() => setIsMenuOpen(false)} className="font-medium tracking-wide text-white transition-colors duration-200 p-2">
                          {t('nav_about')}
                          </Link>
                        </li>
                        <li>
                          <Link to='services' spy={true} smooth={true} offset={-50} duration={1500} onClick={() => setIsMenuOpen(false)} className="font-medium tracking-wide text-white transition-colors duration-200 p-2">
                          {t('nav_services')}
                          </Link>
                        </li>
                        <li>
                          <Link to='portfolio' spy={true} smooth={true} offset={-50} duration={1500} onClick={() => setIsMenuOpen(false)} className="font-medium tracking-wide text-white transition-colors duration-200 p-2">
                          {t('nav_portfolio')}
                          </Link>
                        </li>
                        <li>
                          <Link to='team' spy={true} smooth={true} offset={-50} duration={1500} onClick={() => setIsMenuOpen(false)} className="font-medium tracking-wide text-white transition-colors duration-200 p-2">
                          {t('nav_team')}
                          </Link>
                        </li>
                        <li>
                          <Link to='contact' spy={true} smooth={true} offset={-50} duration={1500} onClick={() => setIsMenuOpen(false)} className="font-medium tracking-wide text-white transition-colors duration-200 p-2">
                          {t('nav_contact')}
                          </Link>
                        </li>
                        {languages.map( ({code, name, country_code}) => (
                          <li key={code} className="w-auto">
                            <button className='dropdown-item pr-24 hover:text-light-blue' 
                            onClick={()=> i18n.changeLanguage(code)}>
                                <span className={`flag-icon flag-icon-${country_code} -ml-8 sm:-ml-0`}>
                                  <span className='px-8 uppercase font-semibold'>
                                  {name}
                                  </span>
                                </span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </nav>
        </div>
      </div>
    );
  };

export default Nav