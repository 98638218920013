import { useTranslation } from 'react-i18next'
import {motion, useInView } from "framer-motion";
import { useRef } from "react";

function FadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s" 
        }}
      >
        {children}
      </span>
    </section>
  );
}

export const Header = () => {  

  const { t } = useTranslation()

    return (
      <div id='uvod' className="h-auto bg-grey mt-16 sm:mt-0">
        <div className="relative bg-gray-100">
          <div className="z-10 absolute inset-0 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
              <div className='relative h-full'>
                <FadeIn>
                <div className="absolute top-1/2 text-center sm:text-center w-full uppercase">
                  <h2 className="sm:px-0 w-full font-sans text-md font-bold leading-none tracking-wide text-white sm:text-xl md:text-2xl lg:text-4xl">
                    {t('header_text_first')}
                  </h2>
                  <h2 className="mt-2 sm:px-0 w-full font-sans text-md font-bold leading-none tracking-wide text-white sm:text-xl md:text-2xl lg:text-4xl">
                    {t('header_text_second')}
                  </h2>
                </div>
                </FadeIn>
              </div>
          </div>
          <div>
            <video autoPlay loop muted playsInline preload='auto' className='w-full h-60 object-cover sm:h-full z-9'>
            <source
              src="../videos/header_vid_x.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  };
export default Header