import React from 'react'
import './App.css';
import Navbar from './components/nav.js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import Team from './components/team.js';
import Portfolio from './components/portfolio.js';
import Services from './components/services.js';

function App() {

  return (
    <div className="App">
        <Navbar/>
        <Header/>
        <Services/>
      <div className='bg-grey'>
        <Portfolio/>
      </div>
      <div className='bg-dark-grey'>
        <Team/>
      </div>
      <div className='bg-grey'>
        <Footer/>
      </div>
    </div>
  );
}

export default App;
