import React from 'react'
import { useTranslation } from 'react-i18next'
import {motion, useInView } from "framer-motion";
import { useRef } from "react";

function FadeIn({ children }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <section ref={ref}>
      <span
        style={{
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s" 
        }}
      >
        {children}
      </span>
    </section>
  );
}


export const Services = () => {
    const { t } = useTranslation()

  return (
    <div>
        <div id='services' className='bg-grey'>
            <div id='promo' className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-white">
                <div className="grid gap-10 lg:grid-cols-2">
                    <div className="flex flex-col justify-center">
                        <div className='relative px-6 py-4'>
                        <FadeIn>
                            <h2 className="mb-4 font-sans text-3xl font-bold tracking-wide text-gray-900 sm:text-4xl sm:leading-none text-center sm:text-right text-white pr-0 sm:pr-2">
                                <span className="relative">
                                <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink sm:invisible" />
                                    <span className="relative inline-block uppercase">
                                    {t('services_promo_title')}
                                    </span>
                                </span>
                            </h2>
                            <p className="mb-6 text-gray-900 text-light text-center sm:text-right pr-0 sm:pr-2">
                            {t('services_promo_p')}
                            </p>
                            <div className="absolute right-1 -bottom-8 h-60 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink invisible md:visible" />
                        </FadeIn>
                        </div>
                    </div>
                    <div className="mb-4 lg:mb-6">
                        <div>
                            <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                            <source
                                src="../videos/2_promo.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div id='flight' className='bg-dark-grey'>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32 text-white">
                <div className="mx-auto sm:text-center lg:max-w-2xl">
                    <div className="relative max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <FadeIn>
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-wide text-gray-900 sm:text-4xl md:mx-auto uppercase">
                    <span className="relative px-1">
                        <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink" />
                        <span className="relative inline-block text-white uppercase">
                        {t('services_aerial_title')}
                        </span>
                    </span> 
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                    {t('services_aerial_p')}
                    </p>
                    </FadeIn>
                    </div>
                    <div className="mb-4 lg:mb-6">
                        <div>
                            <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                            <source
                                src="../videos/3_letecke.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <FadeIn>
                    <p className="max-w-xl mb-4 text-base text-gray-700 sm:mx-auto">
                    {t('services_aerial_p2')}
                    </p>
                    </FadeIn>
                </div>
            </div>
        </div>
        <div id='animation' className='bg-grey'>
            <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-white">
                <div className="grid gap-10 lg:grid-cols-2">
                    <div>
                        <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                        <source
                            src="../videos/4_animacie.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="lg:pr-10 text-left pt-0 sm:pt-14">
                        <div className='relative px-6 py-4'>
                        <FadeIn>
                            <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold tracking-wide text-gray-900 sm:text-4xl sm:leading-none text-left text-white">
                            <span className="relative">
                            <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink sm:invisible" />
                                <span className="relative inline-block uppercase">
                                {t('services_animation_title')}
                                </span>
                            </span>
                            </h2>
                            <p className="mb-6 text-gray-900 text-light">
                            {t('services_animation_p')}
                            </p>
                            <div className="absolute -inset-x-1 bottom-1 h-48 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink invisible md:visible" />
                        </FadeIn>
                        </div>
                    </div>
                </div>
            </div>
            <div id='time' className='bg-dark-grey'>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-white">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <FadeIn>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-wide text-gray-900 sm:text-4xl md:mx-auto uppercase">
                        <span className="relative inline-block">
                            <div className="absolute -inset-x-2 bottom-0 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink sm:invisible" />
                            <span className="relative">{t('services_timelapse_title')}</span>
                        </span>{' '}
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                        {t('services_timelapse_p')} 
                        </p>
                    </FadeIn>
                    </div>
                    <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
                        <div className="flex flex-col justify-center">
                                <div className='relative px-6 py-4'>
                                <FadeIn>
                                    <h2 className="max-w-lg mb-4 font-sans text-2xl font-bold tracking-wide text-gray-900 sm:text-3xl sm:leading-none text-center sm:text-right text-white pr-0 sm:pr-2">
                                    <span className="relative">
                                        <span className="relative inline-block uppercase">
                                        {t('services_timelapse_subtitle')}
                                        </span>
                                    </span>
                                    </h2>
                                    <p className="mb-6 text-gray-900 text-light text-center sm:text-right pr-0 sm:pr-2">
                                    {t('services_timelapse_psub')}
                                    </p>
                                    <div className="absolute right-1 -bottom-8 h-72 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink invisible md:visible" />
                                </FadeIn>
                                </div>
                        </div>
                        <div>
                            <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                            <source
                                src="../videos/5_casozber.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            <div id='automotive' className='bg-grey'>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-white">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <FadeIn>
                        <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                        <span className="relative inline-block">
                            <div className="absolute -inset-x-2 bottom-0 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink sm:invisible" />
                            <span className="relative">{t('services_automotive_title')}</span>
                        </span>{' '}
                        </h2>
                        <p className="text-base text-gray-700 md:text-lg">
                        {t('services_automotive_p')}
                        </p>
                    </FadeIn>
                    </div>
                    <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
                        <div>
                            <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                            <source
                                src="../videos/6_automotive.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="flex flex-col justify-center">
                        <div className='relative px-6 py-4'>
                        <FadeIn>
                            <h2 className="max-w-lg mb-4 font-sans text-2xl font-bold tracking-wide text-gray-900 sm:text-3xl sm:leading-none text-left">
                            <span className="relative">
                                <span className="relative inline-block uppercase">
                                {t('services_automotive_subtitle')}
                                </span>
                            </span>
                            </h2>
                            <p className="mb-6 text-gray-900 text-light text-left">
                            {t('services_automotive_psub')}
                            </p>
                            <div className="absolute -inset-x-1 bottom-1 h-48 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink invisible md:visible" />
                        </FadeIn>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className='bg-dark-grey'>
            <div id='aftermovie' className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32 text-white">
                <div className="mx-auto sm:text-center lg:max-w-2xl">
                    <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                    <FadeIn>
                    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-wide text-gray-900 sm:text-4xl md:mx-auto uppercase">
                    <span className="relative px-1">
                        <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink" />
                        <span className="relative inline-block text-white uppercase">
                        {t('services_aftermovie_title')}
                        </span>
                    </span> 
                    </h2>
                    <p className="text-base text-gray-700 md:text-lg">
                    {t('services_aftermovie_p')}
                    </p>
                    </FadeIn>
                    </div>
                    <div className="mb-4 lg:mb-6">
                        <div>
                            <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                            <source
                                src="../videos/7_after.mp4"
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <FadeIn>
                    <p className="max-w-xl mb-4 text-base text-gray-700 sm:mx-auto">
                    {t('services_aftermovie_p2')} 
                    </p>
                    </FadeIn>
                </div>
            </div>
        </div>
        <div id='foreign' className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-white">
                <div className="grid gap-10 lg:grid-cols-2">
                    <div>
                        <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full h-auto rounded shadow-lg sm:h-auto'>
                        <source
                            src="../videos/9_zahranicie.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="lg:pr-10 text-left pt-0 sm:pt-14">
                        <div className='relative px-6 py-4'>
                        <FadeIn>
                            <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold tracking-wide text-gray-900 sm:text-4xl sm:leading-none text-left text-white">
                            <span className="relative">
                                <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink sm:invisible" />
                                <span className="relative inline-block uppercase">
                                {t('services_aboard_title')}
                                </span>
                            </span>
                            </h2>
                            <p className="mb-6 text-gray-900 text-light">
                            {t('services_aboard_p')}
                            </p>
                            <div className="absolute -inset-x-1 bottom-4 h-60 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink invisible md:visible" />
                        </FadeIn>
                        </div>
                    </div>
                </div>
            </div>
            <div id='social' className='bg-dark-grey'>
                <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 text-white">
                    <div className="grid gap-4 lg:grid-cols-2">
                        <div className="flex flex-col justify-center    ">
                            <div className='relative px-6 py-4'>
                            <FadeIn>
                                <h2 className="mb-4 font-sans text-3xl font-bold tracking-wide text-gray-900 sm:text-4xl sm:leading-none text-center sm:text-right text-white pr-0 sm:pr-2">
                                    <span className="relative">
                                        <div className="absolute -inset-x-2 bottom-1 h-2 transform -skew-x-12 bg-gradient-to-r from-light-blue to-light-pink sm:invisible" />
                                        <span className="relative inline-block uppercase">
                                        {t('services_social_title')}
                                        </span>
                                    </span>
                                </h2>
                                <p className="mb-6 text-gray-900 text-light text-center sm:text-right pr-0 sm:pr-2">
                                {t('services_social_p')}
                                </p>
                                <div className="absolute right-1 -bottom-8 h-72 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink invisible md:visible" />
                            </FadeIn>
                            </div>
                        </div>
                        <div className="mb-4 lg:mb-6 pl-0 sm:pl-8">
                            <div>
                                <video loop  autoPlay playsInline preload='auto' muted className='object-contain w-full sm:w-auto h-96 rounded sm:h-96'>
                                <source
                                    src="../videos/8_social.mp4"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                                </video>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
export default Services