import { useTranslation } from 'react-i18next'

export const Footer = () => {
    const { t } = useTranslation()
    
    return (
    <div id="contact" className="pt-16 mx-auto sm:max-w-lg md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 text-white">
    <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-2">
        <div className="pl-2 sm:pl-0 sm:col-span-1 text-left font-medium">
            <div className="relative mt-6 lg:max-w-md p-2 w-72 ">
                <h2 className="ml-6 font-bold">{t('footer_left_title')}</h2>
                <div className="flex mt-4 ml-6">
                    <svg className="svg-icon fill-white inline mr-2 -ml-1" viewBox="0 0 20 20" width="30px" height="30px">
                        <path d="M13.372,1.781H6.628c-0.696,0-1.265,0.569-1.265,1.265v13.91c0,0.695,0.569,1.265,1.265,1.265h6.744c0.695,0,1.265-0.569,1.265-1.265V3.045C14.637,2.35,14.067,1.781,13.372,1.781 M13.794,16.955c0,0.228-0.194,0.421-0.422,0.421H6.628c-0.228,0-0.421-0.193-0.421-0.421v-0.843h7.587V16.955z M13.794,15.269H6.207V4.731h7.587V15.269z M13.794,3.888H6.207V3.045c0-0.228,0.194-0.421,0.421-0.421h6.744c0.228,0,0.422,0.194,0.422,0.421V3.888z"></path>
                    </svg>
                    <h2 className="inline font-bold">+421 910 397 206</h2>
                </div>
                <div className="flex mt-2 ml-6">
                    <svg className="svg-icon fill-white inline mr-2" viewBox="0 0 20 20" width="30px" height="30px">
                        <path d="M17.388,4.751H2.613c-0.213,0-0.389,0.175-0.389,0.389v9.72c0,0.216,0.175,0.389,0.389,0.389h14.775c0.214,0,0.389-0.173,0.389-0.389v-9.72C17.776,4.926,17.602,4.751,17.388,4.751 M16.448,5.53L10,11.984L3.552,5.53H16.448zM3.002,6.081l3.921,3.925l-3.921,3.925V6.081z M3.56,14.471l3.914-3.916l2.253,2.253c0.153,0.153,0.395,0.153,0.548,0l2.253-2.253l3.913,3.916H3.56z M16.999,13.931l-3.921-3.925l3.921-3.925V13.931z"></path>
                    </svg>
                    <h2 className="inline font-bold">mfa.produ@gmail.com</h2>
                </div>
                <div className="flex mt-2 ml-6">
                    <a href="https://www.instagram.com/mfa_fifty/" target="_blank">
                        <svg viewBox="0 0 30 30" fill="currentColor" className="h-6 inline mr-2">
                            <circle cx="15" cy="15" r="4" />
                            <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                        </svg>
                        <h2 className="pl-2 tracking-wide inline font-bold">mfa_fifty</h2>
                    </a>
                </div>
                <div className="absolute left-2 sm:left-1 -bottom-6 h-48 w-2 transform -skew-y-12 bg-gradient-to-b from-light-blue to-light-pink" />
            </div>
        </div>
        <div className="relative">
            <div className="relative ml-6 sm:ml-0 pl-4 sm:absolute right-0 space-y-2 text-sm text-left font-medium">
                <p className="mr-1 text-gray-800">MFAproduction s.r.o. </p>
                <p className="mr-1 text-gray-800">Eisnerova 6968/6</p>
                <p className="mr-1 text-gray-800">84107 Bratislava</p>
                <br/>
                <p className="mr-1 text-gray-800">IČO: 47129018</p>
                <p className="mr-1 text-gray-800">DIČ: 2023771090</p>
                <p className="mr-1 text-gray-800">{t('footer_right_vat')}</p>
            </div>
        </div>
    </div>
    <div className="flex flex-col-reverse justify-center pt-5 pb-10 lg:flex-row">
        <p className="text-sm text-gray-600 font-light tracking-widest px-2 sm:px-0 ">
            MFAproduction s.r.o. © Copyright 2022   |   All Rights Reserved 
        </p>
    </div>
    </div>
    )
}

export default Footer